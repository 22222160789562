<template>
    <li class="d-flex flex-column gap-2 checklist-item rounded"
        v-on:mouseover="isHovered = true" 
        v-on:mouseleave="isHovered = false"
        @blur="isEditingName = false" :tabindex="checklistItem.index"
        ref="checklistItemRef">
        <div class="d-flex flex-row flex-nowrap gap-2 align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center flex-nowrap gap-2 w-100">
                <input class="form-check-input" :disabled="isNameEmpty" type="checkbox" value="" v-model="checklistItem.value" @change="saveChanges">
                <OContentEditable class="form-control border-0 px-1 py-0 bg-transparent" v-model="checklistItem.Name" ref="checklistItemNameInput"
                    @focus="isEditingName = true"
                    @blur="isEditingName = false"/>
                <button :class="{'invisible': !(!checklistItem.isNewRecord && isHovered)}" class="btn p-0 opacity-75 ms-auto"
                    @mousedown.prevent="" @mouseup="deleteItem">
                    <i class="bi bi-x-lg"></i>
                </button>
            </div>
        </div>
        <div v-if="isEditingName || checklistItem.hasChanges || isNameEmpty" :key="updateAfterSaveKey" ref="checklistButtonContainer" class="align-self-start d-flex flex-row gap-2 w-100">
            <button type="button" :class="{'disabled': !checklistItem.hasChanges || isNameEmpty}" class="btn btn-primary btn-sm" 
                @mousedown.prevent="" @mouseup="saveChanges">
                Save changes
            </button>
            <button type="button" class="btn btn-secondary btn-sm" 
                @mousedown.prevent="" @mouseup="checklistItem.isNewRecord ? deleteItem() : cancelChanges()">
                Cancel
            </button>
        </div>
    </li>
</template>

<script setup>
    import { getDataObjectById } from 'o365.vue.ts'
    import { ref, computed, onMounted } from 'vue'
    import { putSelectorAtEnd } from 'o365.vue.components.Checklists.common.js'
    
    const props = defineProps({
        checklistItem: {
            type: Object,
            required: true
        }
    });
    
    const emit = defineEmits(["saveChecklistItem", "deleteChecklistItem"])

    const isHovered = ref(false);
    const isEditingName = ref(null);
    const checklistItemRef = ref(null);
    const checklistButtonContainer = ref(null);
    const checklistItemNameInput = ref(null);
    const isNameEmpty = computed(() => { return !props.checklistItem.Name })
    
    onMounted(() => {
        if(!props.checklistItem.Name) {
            let itemName = checklistItemNameInput.value.$el;
            itemName.focus();
            putSelectorAtEnd(itemName);
        };
    });

    const updateAfterSaveKey = ref(false);
    function saveChanges() {
        props.checklistItem.isNewRecord = false;
        props.checklistItem.commitChanges(props.checklistItem.changes);
        checklistButtonContainer.value?.classList.add('disabled')
        document.activeElement.blur();
        emit("saveChecklistItem");
        updateAfterSaveKey.value = !updateAfterSaveKey.value;
    }

    function cancelChanges() {
        document.activeElement.blur();
        props.checklistItem.cancelChanges();
    }

    function deleteItem() {
        document.activeElement.blur();
        emit("deleteChecklistItem");
    }
</script>


<style>
    [data-bs-theme="dark"] {
    --checklist-item-color: #252a2e;
    --checklist-item-color-hover: #424649;
    }

    [data-bs-theme="light"] {
    --checklist-item-color: #f8f8f8;
    --checklist-item-color-hover: #f8f9fa;
    }

    .checklist-item:hover {
        background-color: var(--checklist-item-color-hover);
    }
</style>